<template>
  <div class="container">
    <div class="header">
      <van-icon v-if="data.orderStatus === 1" name="passed" />
      <van-icon v-else-if="data.orderStatus === 3" name="close" />
      <van-icon v-else name="info-o" />
      <p>{{ data.orderStatus | filterStatus }}</p>
    </div>
    <van-cell-group style="margin: 40px">
      <van-cell title="OL端末処理通番" :value="data.clientOrderNo" />
      <van-cell title="OLGW決済機関番号" :value="data.gwOrderNo" />
      <van-cell title="OL決済機関番号" :value="data.brandOrderNo" />
      <van-cell title="顧客ID" :value="data.customerId" />
      <van-cell title="決済開始時間" :value="data.orderStartTime" />
      <van-cell title="決済完了時間" :value="data.orderFinishTime" />
      <van-cell title="決済金額" :value="data.orderPrice + ' JPY'" />
    </van-cell-group>

    <van-button type="primary" block @click="handleDeclare">Declare</van-button>
  </div>
</template>

<script>
import { openGet } from '@/apis'
import { isMobile } from '@/utils'

export default {
  name: 'Confirmation',
  filters: {
    filterStatus(status) {
      if (status === 0) {
        return '支払待ち'
      } else if (status === 1) {
        return '決済成功'
      } else if (status === 2) {
        return '決済中'
      } else if (status === 3) {
        return '決済失敗'
      } else if (status === 4) {
        return '決済取消'
      } else if (status === 7) {
        return 'エンドユーザ支払い中'
      } else {
        return 'Unknow status'
      }
    },
  },
  data() {
    return {
      data: {
        gwOrderNo: '',
        clientOrderNo: '',
        brandOrderNo: '',
      },
    }
  },
  created() {
    if (isMobile) {
      this.$router.replace('/m/result')
      return
    }
    this.getResult()
  },
  methods: {
    getResult() {
      const clientOrderNo = this.$route.query.clientOrderNo
      openGet({ clientOrderNo }).then((res) => {
        this.data = res
      })
    },
    handleDeclare() {
      this.$router.push({
        path: '/declare',
        query: {
          clientOrderNo: this.data.clientOrderNo,
          clientDeclareNo: 'DL' + new Date().getTime(),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  margin: 60px auto;
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 28px;
  }
}
.van-button {
  width: 220px;
  margin: 40px auto;
}
.van-cell__value {
  font-weight: 600;
  color: #010101;
}
</style>
